import { render, staticRenderFns } from "./QualityControl.vue?vue&type=template&id=275561e9&scoped=true&"
import script from "./QualityControl.vue?vue&type=script&lang=js&"
export * from "./QualityControl.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "275561e9",
  null
  
)

export default component.exports