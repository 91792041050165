<template>
    <div class="of-h full-height">
        <h4 class="text-primary text-center mb-6 font-poppins-semibold">QUALITY CONTROL</h4>
        <div class="row px-3">
            <div class="col-lg-3">
                <dashboard-card-item text="Post-Thaw Motility" to="/post-thaw-motility/"></dashboard-card-item>
            </div>
            <div class="col-lg-3">
                <dashboard-card-item text="Incubation Test" to="/incubation-test/"></dashboard-card-item>
            </div>
            <div class="col-lg-3">
                <dashboard-card-item text="Host/ Hos-G Test" to="/host-hos-gtest/"></dashboard-card-item>
            </div>
            <div class="col-lg-3">
                <dashboard-card-item text="PIA" to="/pia/"></dashboard-card-item>
            </div>
            <div class="col-lg-3 mt-lg-3">
                <dashboard-card-item text="Sperm Morphology" to="/sperm-morphology/"></dashboard-card-item>
            </div>
            <div class="col-lg-3 mt-lg-3">
                <dashboard-card-item text="Sperm Concentration in Straw" to="/sperm-concentration-in-straw/"></dashboard-card-item>
            </div>
            <div class="col-lg-3 mt-lg-3">
                <dashboard-card-item text="Validation of Photometer" to="/validation-of-photometer/"></dashboard-card-item>
            </div>
            <div class="col-lg-3 mt-lg-3">
                <dashboard-card-item text="Monitor Semen in Long Storage" to="/monitoring-of-semen-quality-under-long-storage/"></dashboard-card-item>
            </div>
            <div class="col-lg-3 mt-lg-3">
                <dashboard-card-item text="Quality of Sub Products" to="/quality-of-sub-products/"></dashboard-card-item>
            </div>
            <div class="col-lg-3 mt-lg-3">
                <dashboard-card-item text="Microbial Load in Neat Semen" to="/microbial-load-of-neat-semen/"></dashboard-card-item>
            </div>
            <div class="col-lg-3 mt-lg-3">
                <dashboard-card-item text="Microbial Load in Frozen Semen" to="/microbial-load-Of-frozen-semen-straw/"></dashboard-card-item>
            </div>
            <div class="col-lg-3 mt-lg-3">
                <dashboard-card-item text="Microbial Load in Glasswares" to="/microbial-load-testing-glassware/"></dashboard-card-item>
            </div>
            <div class="col-lg-3 mt-lg-3">
                <dashboard-card-item text="Microbial load in LAFU" to="/microbial-testing-of-lafu/"></dashboard-card-item>
            </div>
            <div class="col-lg-3 mt-lg-3">
                <dashboard-card-item text="Microbial load in AV Wash" to="/microbial-testing-of-av-wash/"></dashboard-card-item>
            </div>
            <div class="col-lg-3 mt-lg-3">
                <dashboard-card-item text="Calibration of Equipments" to="/calibration-of-equipment/" ></dashboard-card-item>
            </div>
            <div class="col-lg-3 mt-lg-3">
                <dashboard-card-item text="Testing of French Mini Straws" to="/testing-of-french-mini-straw/"></dashboard-card-item>
            </div>
            <div class="col-lg-3 mt-lg-3">
                <dashboard-card-item text="Testing of AI Sheath" to="/testing-Of-ai-sheath-page/"></dashboard-card-item>
            </div>
            <div class="col-lg-3 mt-lg-3">
                <dashboard-card-item text="Testing of Chemicals" to="/test-report-chemical/"></dashboard-card-item>
            </div>
            <div class="col-lg-3 mt-lg-3">
                <dashboard-card-item text="Semen Evaluation by Flow Cytometer" to="/semen-evaluation-by-flow-cytometer/"></dashboard-card-item>
            </div>
            <div class="col-lg-3 mt-lg-3">
                <dashboard-card-item text="Air Culture" to="/air-culture/" ></dashboard-card-item>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'QualityControl'
};
</script>

<style scoped>
</style>
